import { useState, useEffect } from 'react';
import {
  Dialog, IconButton,
  List as MuiList, ListItem, ListItemButton,
  ListItemIcon, ListItemText, Divider,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import BookmarkRemove from '@mui/icons-material/BookmarkRemove';
import { useSnackbar } from 'notistack';
import { useApi, useCurrentUser, useCurrentTeamId } from 'lib/contexts/ApplicationState';
import { ContentList, ContentListsItemType } from 'lib/types';

interface Props {
  itemId: number;
  /** The team id to use if not the current team, useful to override the app state teamId
   * for objects like jobs that have a team associated already */
  teamId?: number;
  itemType: ContentListsItemType;
  onClose: () => void;
  isAdded?: boolean;
}

export const SaveToProjectListModal = ({
  itemId, itemType, teamId, onClose, isAdded = false,
}: Props) => {
  const currentUser = useCurrentUser();
  const API = useApi();
  const currentTeamId = useCurrentTeamId();
  const { enqueueSnackbar } = useSnackbar();

  const [isSendingData, setIsSendingData] = useState(false);
  const [lists, setLists] = useState<any[]>([]);
  const [favoritesList, setFavoritesList] = useState<ContentList | null>(null);

  useEffect(() => {
    const fetchLists = async (_teamId: number) => {
      const result = await API.getProjectLists(_teamId);
      setFavoritesList(result.find((l: ContentList) => l.name === 'Favorites') || null);
      setLists(result.filter((l: ContentList) => l.name !== 'Favorites'));
    };

    if (currentUser && (currentTeamId || teamId)) {
      const teamIdToUse = teamId || currentTeamId;
      if (!teamIdToUse) return;
      fetchLists(teamIdToUse);
    }
  }, [currentUser, currentTeamId]);

  const addToList = async (list: ContentList) => {
    try {
      setIsSendingData(true);
      await API.addItemToList(list.id, itemId, itemType);
      setIsSendingData(false);
      onClose();
      enqueueSnackbar(`Added to ${list.name}!`, {
        variant: 'success',
        autoHideDuration: 9000,
      });
    } catch (error) {
      enqueueSnackbar(`Error adding ${itemType} to list: ${error}`, {
        variant: 'error',
      });
    }
  };

  const removeFromList = async (list: ContentList) => {
    try {
      setIsSendingData(true);
      await API.removeItemFromList(list.id, itemId);
      setIsSendingData(false);
      onClose();
      enqueueSnackbar(`Removed from ${list.name}`, {
        variant: 'success',
      });
    } catch (err) {
      setIsSendingData(false);
      enqueueSnackbar(`Error removing ${itemType} from list: ${err}`, {
        variant: 'error',
      });
    }
  };

  const save = async (list: ContentList, added: boolean) => {
    if (!list.id) return;

    if (!added && list.name === 'Favorites') {
      addToList(list);
    } else if (added && list.name === 'Favorites') {
      removeFromList(list);
    } else {
      addToList(list);
    }
  };

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <div className="absolute right-2 top-2 text-orange">
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="p-4">
        <>
          <div className="flex flex-wrap sm:flex-nowrap items-center">
            <h1 className="text-lg text-orange font-bold mr-5">
              Save
              {' '}
              {itemType}
              {' '}
              to a list
            </h1>
          </div>
          <div>
            <MuiList>
              {currentTeamId && !lists.length && (
                <>
                  No projects found for this team.
                </>
              )}
              {lists.map((l) => (
                <ListItem key={l.id} disablePadding>
                  <ListItemButton
                    disabled={isSendingData}
                    onClick={() => save(l, isAdded)}
                  >
                    <ListItemIcon>
                      {(isAdded && l.name === 'Favorites') ? <CheckIcon color="success" /> : <AddIcon color="inherit" />}
                    </ListItemIcon>
                    <ListItemText>{l.name}</ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
              <Divider />
              {favoritesList && (
                <ListItem disablePadding>
                  <ListItemButton
                    disabled={isSendingData}
                    onClick={() => save(favoritesList, isAdded)}
                  >
                    <ListItemIcon>
                      {isAdded ? <CheckIcon color="success" /> : <AddIcon color="inherit" />}
                    </ListItemIcon>
                    <ListItemText>Favorites</ListItemText>
                    <ListItemIcon>
                      {isAdded && <div className="text-gold"><BookmarkRemove fontSize="large" color="inherit" /></div>}
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              )}
            </MuiList>
          </div>
        </>
      </div>
    </Dialog>
  );
};
