import { BookmarkAddOutlined, BookmarkAdded } from '@mui/icons-material';
import { useCurrentUser } from 'lib/contexts/ApplicationState';
import { useSignUpModal } from 'components/SignUpModal';
import { SaveToProjectListModal } from 'components/SaveToList/SaveToProjectListModal';
import { useModal } from 'mui-modal-provider';
import { ContentListsItemType } from 'lib/types';

interface Props {
  itemId: number;
  /** The team id to use if not the current team, useful to override the app state teamId
   * for objects like jobs that have a team associated already */
  teamId?: number;
  itemType: ContentListsItemType;
  text?: string;
  isFavorited?: boolean;
}

export const SaveToProjectListButton = ({
  itemId, teamId, itemType, text, isFavorited,
}: Props) => {
  const currentUser = useCurrentUser();
  const { showSignUpModal } = useSignUpModal();
  const { showModal } = useModal();

  const click = () => {
    if (!currentUser) {
      showSignUpModal(`Please sign up or sign in to save ${itemType}s`);
      return;
    }
    const modal = showModal(SaveToProjectListModal, {
      itemId,
      teamId,
      itemType,
      onClose: () => {
        modal.destroy();
      },
      isAdded: isFavorited,
    });
  };

  const buttonToDisplay = () => {
    if (isFavorited) {
      return <BookmarkAdded color="primary" />;
    }
    if (text) {
      return text;
    }
    return <BookmarkAddOutlined color="primary" />;
  };

  return (
    <div>
      <button
        type="button"
        onClick={click}
        aria-label="save or unsave"
        color="inherit"
        title="Save or unsave"
        className="hover:bg-hintgray/50 rounded-full"
      >
        {buttonToDisplay()}
      </button>
    </div>
  );
};
